
import moment from "moment";
import { defineComponent, ref, computed, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "notifications-list",
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const loading = ref<boolean>(true);
    const notificationsList = ref<any[]>([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalItems = ref(0);
    const SchoolarYears = ref([
      {
        _id: "",
        name: "",
      },
    ]);
    ApiService.post("/schoolaryears/filter", {
      query: { $query: {}, $orderby: { end: -1 } },
    }).then((res) => {
      const data = res.data.map((sc) => {
        return {
          ...sc,
          name:
            new Date(sc["start"]).getFullYear() +
            "-" +
            new Date(sc["end"]).getFullYear(),
        };
      });
      SchoolarYears.value = data;
    });
    const paginatedNotifications = computed(() => {
      const start = (currentPage.value - 1) * pageSize;
      const end = start + pageSize;
      return notificationsList.value.slice(start, end);
    });

    const getHomeworkLink = async (id: string): Promise<string> => {
      try {
        const response = await ApiService.get(`/lms/student/homework/${id}`);
        return "/devoirs/" + response.data.subject._id + "/exercise/" + id;
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          return `/resource-deleted?type=homework`;
        }
        return "/devoirs";
      }
    };

    const getCourseLink = async (id: string): Promise<string> => {
      try {
        const response = await ApiService.get(`/lms/student/course/${id}`);
        return "/courses/" + response.data.subject._id + "/course/" + id;
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          return `/resource-deleted?type=course`;
        }
        return "/courses";
      }
    };

    const getNotificationLink = async (notif: any): Promise<string> => {
      switch (notif.refType) {
        case "newHomework":
          return await getHomeworkLink(notif.ref);
        case "newCourse":
          return await getCourseLink(notif.ref);
        case "newBulletin":
          return "/bulletin";
        case "newEmploi":
          return "/emplois";
        case "StudentPunishmentAccepted":
          return "/";
        case "adminMessage":
          return "/chat";
        case "newActuality":
          return "/actualites";
        case "newComment":
          return "/actualites";
        case "newEvent":
          return "/calendar";
        case "newPayment":
          return "/paiement";
        case "newRegistration":
          return "/";
        case "attendance":
          return "/attendance";
        default:
          return "/";
      }
    };

    const loadNotifications = async () => {
      loading.value = true;
      try {
        const { data } = await ApiService.get("/notifications");
        const promises = data.notifications.map(async (notif: any) => {
          const link = await getNotificationLink(notif);
          return {
            ...notif,
            link,
          };
        });

        const mappedData = await Promise.all(promises);
        notificationsList.value = mappedData;
        totalItems.value = mappedData.length;

        await ApiService.post("/notifications/mark-as-read", {});
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      } finally {
        loading.value = false;
      }
    };

    const handleCurrentChange = (val: number) => {
      currentPage.value = val;
    };

    const changeStudent = (activeStudent: any, redirectTo?: string) => {
      let studentYears = Object.keys(activeStudent.schoolarYearsHistory);
      const year = studentYears[studentYears.length - 1];
      window.localStorage.setItem("activeSchoolarYear", year);
      if (redirectTo) {
        window.localStorage.setItem("redirectAfterReload", redirectTo);
      }
      store.commit(
        "setCurrentYear",
        SchoolarYears.value.find((obj) => {
          return obj._id === year;
        })
      );
      store.commit("setStudent", activeStudent);
      window.location.reload();
    };

    const handleNotificationClick = (notification: any) => {
      // For notifications without student specifics or no active student
      if (
        !notification.students?.length ||
        !localStorage.getItem("activeStudent")
      ) {
        router.push(notification.link);
        return;
      }
      // Check if we need to change student
      if (
        !notification.students.some(
          (student) => student._id === localStorage.getItem("activeStudent")
        )
      ) {
        const newStudent = store.getters.currentUser.students.find(
          (student) => student._id === notification.students[0]._id
        );
        if (newStudent) {
          changeStudent(newStudent, notification.link);
          return;
        }
      }
      // If no student change needed, navigate to link
      router.push(notification.link);
    };

    onMounted(async () => {
      const redirectUrl = window.localStorage.getItem("redirectAfterReload");
      if (redirectUrl) {
        window.localStorage.removeItem("redirectAfterReload");
        router.push(redirectUrl);
      }
      await loadNotifications();
    });

    return {
      t,
      moment,
      loading,
      paginatedNotifications,
      currentPage,
      totalItems,
      handleCurrentChange,
      apiUrl: store.getters.serverConfigUrl.base_url + "/",
      handleNotificationClick,
    };
  },
});
